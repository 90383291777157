
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.main {
  $root: &;
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  height: 100vh;
  overflow: hidden;
  background-color: $color-grayscale-05;

  &__content {
    width: calc(100vw - #{$sidebar-width});
    padding: 0 40px 30px;
    transition: width $base-animation-time;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    @include for-size(phone-landscape-down) {
      padding: 0 24px 24px;
    }
    @include for-size(phone-portrait-down) {
      padding: 0 20px 20px;
    }
    &--frame-mode {
      padding: 0;
      background-color: $color-white;
    }
  }

  &__content-trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    visibility: hidden;
    pointer-events: none;
  }

  &--collapse {
    #{$root}__content {
      width: calc(100vw - #{$sidebar-collapse-width});
    }
  }
}
.page-wrapper {
  position: relative;
  width: 100%;
  flex: 1;
}
